import React, { memo, useCallback, useEffect, useState } from "react";
import Table from "./Table";
import CreateCoupon from "../../models/CreateCoupon";
import { GetAllCouponService } from "../../../services/coupon";
import { UpdateStatusService } from "../../../services/coupon/updateStatus.service";
import toast from "react-hot-toast";
import ComponentLoading from "../../common/ComponentLoading";

export interface COUPON_INTERFACE {
  _id: string;
  couponCode: string;
  discount: number;
  validTill: string;
  couponCodeAssociatedWIth: string;
  isActive: boolean;
}

interface CouponProps {
  // define props here
}

const Coupon: React.FC<CouponProps> = (props) => {
  const [createCoupon, setCreateCoupon] = useState<boolean>(false);
  const [couponCode, setCouponCode] = useState<COUPON_INTERFACE[]>([]);
  const [isLoading,setIsLoading]=useState<boolean>(true)

  //Get All Coupons
  useEffect(() => {
    getAllCoupons();
  }, []);

  //Get All Coupons
  const getAllCoupons = useCallback(async (query?: string) => {
    const response = await GetAllCouponService(query);
    if (response?.statusCode) {
      setCouponCode(response?.data?.coupons);
    }
    setIsLoading(false)
  }, []);

  //Change Status
  const changeStatusOfDiscount = useCallback(
    async (status: boolean, couponId: string) => {
      try {
        const response = await UpdateStatusService(couponId, status);
        if (response.statusCode == 200) {
          toast.success(response?.message);
          getAllCoupons();
        }
        console.log(response);
      } catch (error) {
        throw error;
      }
    },
    []
  );

  if (isLoading) {
    return <ComponentLoading />;
  }
  return (
    <main>
      <nav className="flex flex-row-reverse p-2">
        <button
          onClick={() => setCreateCoupon(true)}
          className="border-[1px] rounded px-2 py-2 border-customBlue text-customBlue hover:text-white  hover:bg-customBlue"
        >
          Create Coupon
        </button>
      </nav>
      <section className="pt-6">
        <Table
          coupon={couponCode}
          changeStatusOfDiscount={changeStatusOfDiscount}
          getAllCoupons={getAllCoupons}
        />
      </section>
      {createCoupon && <CreateCoupon isClose={setCreateCoupon} />}
    </main>
  );
};

export default memo(Coupon);
