import React from 'react';

interface IndexProps {
  // define props here
}

const UserDetails : React.FC<IndexProps> = (props) => {
  return (
    <main>
      <h1>Index</h1>
    </main>
  );
};

export default UserDetails;