import React, { useState } from "react";
import { MdDashboardCustomize } from "react-icons/md";
import { FaFolderMinus } from "react-icons/fa";
import { FaFile } from "react-icons/fa";
import { PiStudentFill } from "react-icons/pi";
import UploadFile from "../../models/UploadFile";
import CreateFolder from "../../models/CreateFolder";
import { adminSideBar, SIDEBAR, userSideBar } from "../../../data/sidebar";
import { useNavigate } from "react-router-dom";
import { getTokenData } from "../../../utils";

interface IndexProps {
  // define props here
}

const Sidebar: React.FC<IndexProps> = (props) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState({
    uploadFile: false,
    createFolder: false,
  });

  const openHandler = (link: string, state: string) => {
    if (link == "") {
      setIsOpen({
        ...isOpen,
        [state]: true,
      });
    } else {
      navigate(link);
    }
  };

  //Token
  let token: any = getTokenData();

  const [sideBar, setSideBar] = useState<SIDEBAR[]>(
    token && token?.isAdmin ? adminSideBar : userSideBar
  );

  return (
    <aside className="bg-customBlue h-[100vh]  w-full">
      <ul className="p-4  space-y-3 text-white py-10">
        {sideBar?.map(({ link, name, state }: SIDEBAR, index: number) => {
          return (
            <li key={index} className="flex items-center gap-2 cursor-pointer">
              <span>
                <MdDashboardCustomize size={23} />
              </span>
              <span className="" onClick={() => openHandler(link, state)}>
                {name}
              </span>
            </li>
          );
        })}
      </ul>
      {isOpen?.uploadFile && <UploadFile isClose={setIsOpen} />}
      {isOpen?.createFolder && <CreateFolder isClose={setIsOpen} />}
    </aside>
  );
};

export default Sidebar;
