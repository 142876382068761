import axiosObject from "../config";

export const updateStatus = async (status: boolean, userId: string) => {
  try {
    const uri: string = `user/update-status/${userId}`;
    const response = await axiosObject.patch(uri, { status });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};
