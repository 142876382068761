import { getAllFolder } from "../../../services/folders/getAllFolder";
import React, { useEffect, useState } from "react";
import { FaFolder } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RiFolderLockFill } from "react-icons/ri";
import { spawn } from "child_process";
import Swal from "sweetalert2";
import { FaRegFileVideo } from "react-icons/fa6";
import { FaRegFilePdf } from "react-icons/fa";
import { getTokenData } from "../../../utils";
import ShowPDF from "../../models/ShowPDF";
import PDFLogo from "../../../assets/logo/pdfLogo.png";
import VideoLogo from "../../../assets/logo/videoLogo.png";
import Loader from "../../common/Loader";
import ComponentLoading from "../../common/ComponentLoading";
import ReactPlayer from "react-player";
import VideoPlayer from "../../models/VideoPlayer";

interface IndexProps {
  // define props here
}

const Folder: React.FC<IndexProps> = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState<any>([]);
  const [files, setFiles] = useState<any>([]);
  const [header, setHeader] = useState<any>([]);
  const [viewFile, setViewFile] = useState({
    PDF: false,
    VIDEO: false,
  });
  const [file, setFile] = useState("");
  const { folderId } = useParams<{ folderId: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const videoBase64 =
    "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4";

  useEffect(() => {
    setIsLoading(true);
    setFiles([]);
    setData([]);

    (async () => {
      const payload = folderId == undefined ? 0 : folderId;
      const response = await getAllFolder(payload);
      if (response.statusCode == 200) {
        setData([...response.data?.folders]);
        setHeader([...response?.data?.header]);
        setFiles(response?.data?.files);
      }
      setIsLoading(false);
    })();
  }, [folderId]);

  const nonAccsessFolderHandler = (courseId: string) => {
    Swal.fire({
      title: "Folder Access Denied",
      text: "Enroll in our courses for Unlock this folder.",
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "Buy Course",
    }).then((result) => {
      if (result?.isConfirmed) {
        navigate(`/dashboard/cart/${courseId}`);
      }
    });
  };

  //Token
  let token: any = getTokenData();
  const [isAdmin, setIsAdmin] = useState<boolean>(
    token?.isAdmin ? true : false
  );

  if (isLoading) {
    return <ComponentLoading />;
  }

  return (
    <>
      <main>
        <div className="bg-white p-5  rounded-full shadow-xl">
          <ul className="flex items-center gap-2 font-semibold">
            <Link to={"/dashboard"} className="cursor-pointer">
              Dashboard /
            </Link>
            {header?.map((item: any, index: number) => (
              <li key={index} className="text-customBlue cursor-pointer">
                <Link to={`/dashboard/${item?._id}`}> {item?.folderName}</Link>{" "}
                /
              </li>
            ))}
          </ul>
        </div>
        <div className="flex items-center gap-10">
          <ul className="flex items-center gap-10 pt-4">
            {data?.map(
              (
                {
                  _id,
                  folderName,
                  isAccess,
                  courseId,
                }: {
                  _id: string;
                  folderName: string;
                  isAccess: boolean;
                  courseId: string;
                },
                index: string
              ) => {
                return (
                  <li
                    key={index}
                    className="text-customRed cursor-pointer disabled:cursor-not-allowed"
                  >
                    {isAccess || isAdmin ? (
                      <Link
                        to={`/dashboard/${_id}`}
                        className="flex flex-col items-center justify-center"
                      >
                        <FaFolder size={80} />
                        <h2 className="text-center font-semibold leading-3">
                          {folderName}
                        </h2>
                      </Link>
                    ) : (
                      <span
                        onClick={() => nonAccsessFolderHandler(courseId)}
                        className="flex flex-col items-center justify-center"
                      >
                        <RiFolderLockFill size={80} />
                        <h2 className="text-center font-semibold leading-3">
                          {folderName}
                        </h2>
                      </span>
                    )}
                  </li>
                );
              }
            )}
          </ul>
          <ul className="grid grid-cols-4 md:grid-cols-5 gap-10 pt-4">
            {files?.length > 0 &&
              files?.map(
                (
                  {
                    fileName,
                    filePath,
                    fileExtension,
                  }: {
                    fileName: string;
                    filePath: string;
                    fileExtension: string;
                  },
                  index: string
                ) => {
                  return (
                    <li
                      key={index}
                      onClick={() => setFile(filePath)}
                      className="text-gray-800 cursor-pointer disabled:cursor-not-allowed"
                    >
                      <span
                        onClick={() =>
                          setViewFile({ ...viewFile, [fileExtension]: true })
                        }
                        className="flex flex-col items-center justify-center"
                      >
                        {fileExtension === "PDF" ? (
                          <img src={PDFLogo} alt="pdf" width={50} />
                        ) : (
                          <img src={VideoLogo} alt="video" width={60} />
                        )}
                        <h2 className="text-center font-semibold leading-3 pt-2">
                          {fileName}
                        </h2>
                      </span>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
        {viewFile?.PDF && <ShowPDF isClose={setViewFile} filePath={file} />}
        {viewFile.VIDEO && (
          <VideoPlayer isClose={setViewFile} filePath={file} />
        )}
      </main>
    </>
  );
};

export default Folder;
