import React from "react";
import Logo from "../../../assets/logo/logo.png";
import { IoIosSettings } from "react-icons/io";
import { IoIosNotifications } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { getTokenData } from "../../../utils";

interface IndexProps {
  // define props here
}

const Header: React.FC<IndexProps> = (props) => {
  const navigate = useNavigate();

  const logoutHandler = () => {
    localStorage.removeItem("auth_token");
    navigate("/");
  };

  //Token
  let token: any = getTokenData();

  return (
    <header className="bg-white  px-6 text-gray-800 border-b-[1px] shadow-2xl shadow-customBlue font-semibold flex items-center justify-between">
      <div>
        <img src={Logo} alt="logo" className="w-16" />
      </div>
      <section className="flex items-center gap-20 ">
  
        <div className="flex items-center gap-3 group cursor-pointer">
          <img
            src="https://th.bing.com/th/id/OIP.jixXH_Els1MXBRmKFdMQPAHaHa?w=199&h=199&c=7&r=0&o=5&dpr=1.5&pid=1.7"
            alt="user"
            className="h-8 w-8 rounded-full object-cover"
          />
          <h5 className="text-[15px]">   {token?.name}</h5>
          <div
            className="z-50 group-hover:flex flex-col absolute top-9 right-4 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
            id="user-dropdown"
          >
            <div className="px-4 py-3">
              <span className="block text-sm text-gray-900 dark:text-white">
                {token?.name}
              </span>
              <span className="block text-sm  text-gray-500 truncate dark:text-gray-400">
                {token?.email}
              </span>
            </div>
            <ul className="py-2" aria-labelledby="user-menu-button">
              <li>
                <Link
                  to={"/dahboard"}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                >
                  Dashboard
                </Link>
              </li>
              <li>
                <a
                  href="#"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                >
                  Settings
                </a>
              </li>

              <li
                onClick={logoutHandler}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                Sign out
              </li>
            </ul>
          </div>
        </div>
      </section>
    </header>
  );
};

export default Header;
