import React, { useEffect, useState } from "react";
import { RxCross2 } from "../../../assets/icons";
import Button from "../../common/Button";
// import {uploadFile} from "../../../services/files";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { getAllFolder } from "../../../services/folders/getAllFolder";
import { accessOfFolders } from "../../../services/folders/accessOfFolders";
import { GetAccessOfCourse } from "../../../services/folders";
import { MultiSelect } from "react-multi-select-component";

interface UploadFileProps {
  isClose?: any;
  userId: any;
}

const FolderAccess: React.FC<UploadFileProps> = ({ userId, isClose }) => {
  const params = useParams();
  const [folders, setFolders] = useState<any>([]);
  const [selectedCities, setSelectedCities] = useState<any>([]);

  useEffect(() => {
    (async () => {
      const response = await getAllFolder("0");
      if (response?.statusCode == 200) {
        setFolders(
          [...response?.data?.folders].map((item) => {
            return { label: item?.folderName, value: item?._id };
          })
        );
      }
      const accessOfCourse = await GetAccessOfCourse(userId);
      if (accessOfCourse?.statusCode == 200) {
        setSelectedCities([
          ...accessOfCourse?.data?.map((item: any) => {
            return { value: item?.code, label: item?.name };
          }),
        ]);
      }
    })();
  }, []);

  const param = params["*"] || null;

  const submitHandlder = async () => {
    try {
      const payload = {
        folderAccess: selectedCities?.map((item: any) => item?.value),
      };
      const response = await accessOfFolders(userId, payload);
      if (response?.status) {
        toast.success("Access Successfully!");
      }
    } catch (error) {
      throw error;
    } finally {
      isClose(false);
    }
  };
  const closeHandle = (e: any) => {
    if (e.target.id == "uploadBox") isClose(false);
  };

  return (
    <main
      id="uploadBox"
      onClick={closeHandle}
      className="absolute top-0 flex justify-center items-center z-10 inset-0 bg-black bg-opacity-50"
    >
      <div className="bg-white w-[35%] h-[34vh] rounded space-y-2 p-3 text-black  shadow-lg">
        <div className="flex flex-row-reverse">
          <button
            onClick={(e) => isClose(false)}
            className=" cursor-pointer hover:text-red-500 font-semibold"
          >
            <RxCross2 size={24} />
          </button>
        </div>
        <h1 className="text-black  text-xl font-semibold ">Create Folder</h1>
        <div className="card flex justify-content-center  w-full">
          <MultiSelect
            options={folders}
            value={selectedCities}
            onChange={setSelectedCities}
            labelledBy="Select"
            className="w-full"
          />
        </div>
        <Button onClick={submitHandlder}>Submit</Button>
      </div>
    </main>
  );
};

export default FolderAccess;
