import React, { memo, useEffect, useState } from "react";
import { RxCross2 } from "../../../assets/icons";
import Button from "../../common/Button";
import Input from "../../common/Input";
import { getAllCourses } from "../../../services/course";
import { MultiSelect } from "react-multi-select-component";
import { CreateCouponService } from "../../../services/coupon";
import toast from "react-hot-toast";

interface UploadFileProps {
  isClose?: any;
}

const CreateCoupon: React.FC<UploadFileProps> = ({ isClose }) => {
  const [courses, setCourses] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const [selectedCourses, setSelectedCourses] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);
  const [value, setValue] = useState<{
    associatedWithCourse: string[];
    couponCode: string;
    discount: number;
    validTill: string;
  }>({
    associatedWithCourse: [],
    couponCode: "",
    discount: 0,
    validTill: Date(),
  });

  console.log("i am from create Coupon");
  //Get All Courses
  useEffect(() => {
    (async () => {
      const response = await getAllCourses();
      if (response?.statusCode == 200) {
        setCourses(
          response?.data?.map((item: any) => {
            return {
              label: item?.courseName,
              value: item?._id,
            };
          })
        );
      }
    })();
  }, []);

  const submitHandlder = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await CreateCouponService({
        ...value,
        associatedWithCourse: selectedCourses?.map(
          (course: any) => course.value
        ),
      });
      if (response.statusCode == 201) toast.success(response?.message);
      else toast.error(response?.response?.data?.message);
    } catch (error) {
      throw new Error();
    } finally {
      isClose(false);
    }
  };
  const closeHandle = (e: any) => {
    if (e.target.id == "uploadBox") isClose(false);
  };

  return (
    <main
      id="uploadBox"
      onClick={closeHandle}
      className="absolute top-0 flex justify-center items-center z-10 inset-0 bg-black bg-opacity-50"
    >
      <div className="bg-white w-[35%] min-h-[65vh] rounded space-y-2 p-3 text-black  shadow-lg">
        <div className="flex justify-between">
          <h1 className="text-customBlue  text-xl font-semibold ">
            Create New Coupon Code
          </h1>
          <button
            onClick={(e) => isClose(false)}
            className=" cursor-pointer hover:text-red-500 font-semibold"
          >
            <RxCross2 size={24} />
          </button>
        </div>

        <form onSubmit={submitHandlder} className="grid grid-cols-1 gap-4">
          <div className="card flex flex-col justify-content-center  w-full">
            <label htmlFor="">
              Course Associated with (select atleast 1 course)
              <span className="text-red-500">*</span>
            </label>
            <MultiSelect
              options={courses}
              value={selectedCourses}
              onChange={setSelectedCourses}
              labelledBy="Select"
              className="w-full rounded border-gray-400"
            />
          </div>
          <Input
            className=""
            type="text"
            placeHolder="Coupon Code"
            labelText="Coupon Code"
            name="couponCode"
            value={(value?.couponCode).toUpperCase()}
            onChange={(e) => setValue({ ...value, couponCode: e.target.value })}
            required
          />
          <Input
            className=""
            type="number"
            min={1}
            max={90}
            placeHolder="Discount (In %)"
            labelText="Discount (In %)"
            name="discount"
            value={value?.discount}
            onChange={(e) =>
              setValue({ ...value, discount: Number(e.target.value) })
            }
            required
          />
          <Input
            className=""
            type="date"
            placeHolder="Vaild till"
            labelText="Valid till"
            name="validTill"
            value={value?.validTill}
            onChange={(e) => setValue({ ...value, validTill: e.target.value })}
            required
          />
          <Button type="submit">Generate Coupon</Button>
        </form>
      </div>
    </main>
  );
};

export default memo(CreateCoupon);
