import axiosObject from "../config";

export const preRegisterCouponService = async (
  coupon: any,
  courseName: string
) => {
  console.log("course", courseName);
  try {
    const uri: string = `coupon/status-pre-register?coupon=${coupon}&courseName=${courseName}`;
    const response = await axiosObject.get(uri);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};
