import React, { useState } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

// Configure the PDF worker source
interface UploadFileProps {
  isClose?: (callback: any) => void;
  filePath: string;
}

const ShowPDF: React.FC<UploadFileProps> = ({ isClose, filePath }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);

  const closeHandle = (e: any) => {
    if (e.target.id === "uploadBox") {
      isClose && isClose((prev: any) => ({ ...prev, PDF: false }));
    }
  };
  const handleContextMenu = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent the context menu from showing
  };

  const [isLoading, setIsLoading] = useState(true);

  const handleIframeLoad = () => {
    setIsLoading(false); // Set loading to false when iframe has loaded
  };

  return (
    <main
      id="uploadBox"
      onClick={() =>
        isClose && isClose((prev: any) => ({ ...prev, PDF: false }))
      }
      className="absolute top-0 flex justify-center items-center z-10 inset-0 bg-black bg-opacity-50"
    >
      <span
        onClick={closeHandle}
        className="absolute top-10 right-20 text-customRed h-8 w-8 rounded-full border-2 flex items-center justify-center cursor-pointer border-customRed text-xl"
      >
        X
      </span>
      <div
        onContextMenu={(e) => e.preventDefault()}
        className="bg-white w-[98vw] h-[98vh] rounded space-y-2 p-3 text-black  shadow-lg "
      >
        {isLoading && (
          <div className="loader flex items-center justify-center h-full">
            {/* You can use any loader here (e.g., a spinner, text, etc.) */}
            <p className="text-3xl font-semibold">Loading...</p>
          </div>
        )}
        <iframe
          src={`https://docs.google.com/viewer?url=${encodeURIComponent(
            filePath
          )}&embedded=true`}
          width="100%"
          height="800px"
          frameBorder="0"
          sandbox="allow-scripts allow-same-origin"
          onContextMenu={(e) => e.preventDefault()} // Prevent right-click context menu
          onLoad={handleIframeLoad} // Trigger when iframe content is loaded
          // style={{ pointerEvents: "none" }} // Disables interaction with the PDF content
        ></iframe>
        {/* <div
          className="absolute inset-0"
          onContextMenu={(e) => e.preventDefault()} // Prevent right-click
          style={{ background: "transparent" }} // Invisible overlay
        ></div> */}
      </div>
    </main>
  );
};

export default ShowPDF;
