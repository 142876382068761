import React, { useCallback, useEffect, useState } from "react";
import Table from "./Table";
import { getAllUsers, updateStatus } from "../../../services/user";
import ComponentLoading from "../../common/ComponentLoading";
import toast from "react-hot-toast";

interface IndexProps {
  // define props here
}

const Students: React.FC<IndexProps> = (props) => {
  const [users, setUser] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const response = await getAllUsers();
    if (response?.statusCode == 200) {
      setUser([...response?.data?.user]);
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <ComponentLoading />;
  }

  //Change Status
  const changeStatusOfDiscount = async (status: boolean, couponId: string) => {
    try {
      const response = await updateStatus(status, couponId);
      if (response.statusCode == 200) {
        toast.success(response?.message);
        getUserData();
      }
      console.log(response);
    } catch (error) {
      throw error;
    }
  };

  return (
    <main>
      <Table users={users} changeStatusOfDiscount={changeStatusOfDiscount} />
    </main>
  );
};

export default Students;
