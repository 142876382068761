import React from "react";

interface IndexProps {
  // define props here
}

const ComponentLoading: React.FC<IndexProps> = (props) => {
  return (
    <div className="fixed z-[60] inset-0 w-full h-full bg-black bg-opacity-30 flex items-center justify-center">
      <div
        className="h-[40px] w-[40px] animate-spin rounded-full border-4 border-solid border-current border-t-transparent align-[-0.125em] text-customBlue"
        role="status"
      ></div>
    </div>
  );
};

export default ComponentLoading;
