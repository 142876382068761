"use client";
import React, { memo, useContext, useState } from "react";
import { LiaLongArrowAltRightSolid } from "react-icons/lia";
import { IoIosLogOut } from "react-icons/io";
import Loader from "../../../common/Loader";
import { Link } from "react-router-dom";

interface IndexProps {
  // Define props here
  displayRazorpay?: boolean;
  handleCreateOrder?: any;
  userDetails?: any;
  strikePrice?: number;
  currentPrice?: number;
  courseName?: string;
  couponCodeHandler?: any;
  discount: number;
}

const CoursePayment: React.FC<IndexProps> = ({
  displayRazorpay,
  handleCreateOrder,
  userDetails,
  currentPrice,
  strikePrice,
  courseName,
  discount,
  couponCodeHandler,
}) => {
  const [couponCode, setCouponCode] = useState<string>();
  return (
    <>
      <section className="bg-white py-8 antialiased dark:bg-gray-900 md:py-16">
        <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
          <div className="mx-auto max-w-5xl">
            <Link
              to="/"
              className="text-sm text-customBlue font-sans font-semibold py-1 cursor-pointer"
            >
              Back to course
            </Link>

            <div className="mt-6 sm:mt-8 lg:flex lg:items-start lg:gap-12">
              <form
                action="#"
                onSubmit={(e) => couponCodeHandler(e, couponCode,courseName)}
                className="w-full rounded-lg border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800 sm:p-6 lg:max-w-xl lg:p-8"
              >
                <div className="mb-6 grid grid-cols-2 gap-4">
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      htmlFor="full_name"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {" "}
                      First Name
                    </label>
                    <input
                      type="text"
                      id="full_name"
                      disabled
                      value={userDetails?.firstName}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
                      placeholder="Bonnie Green"
                      required
                    />
                  </div>

                  <div className="col-span-2 sm:col-span-1">
                    <label
                      htmlFor="full_name"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {" "}
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="full_name"
                      value={userDetails?.lastName}
                      disabled
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
                      placeholder="Bonnie Green"
                      required
                    />
                  </div>

                  <div className="col-span-2 sm:col-span-1">
                    <label
                      htmlFor="full_name"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {" "}
                      Email
                    </label>
                    <input
                      type="text"
                      id="full_name"
                      value={userDetails?.email}
                      disabled
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
                      placeholder="Bonnie Green"
                      required
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <label
                      htmlFor="full_name"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {" "}
                      Phone
                    </label>
                    <input
                      type="text"
                      disabled
                      id="full_name"
                      value={userDetails?.phone}
                      className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500"
                      placeholder="Bonnie Green"
                      required
                    />
                  </div>
                  <div className="col-span-2 sm:col-span-2">
                    <label
                      htmlFor="full_name"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                    >
                      {" "}
                      CouponCode
                    </label>
                    <div className="flex justify-between w-full border rounded-lg text-gray-900 focus:border-primary-500 focus:ring-primary-500  dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500">
                      <input
                        type="text"
                        id="full_name"
                        value={couponCode}
                        onChange={(e) =>
                          setCouponCode(e.target.value.toUpperCase())
                        }
                        className="block w-full outline-none    border-gray-300 bg-gray-50 p-2.5 text-sm  "
                        placeholder="COUPON CODE"
                        required
                      />
                      <button
                        type="submit"
                        className="px-5 bg-green-600 rounded-r-lg text-white font-semibold"
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>

                {/* <button
                  type="submit"
                  className="flex w-full items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4  focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Pay now
                </button> */}
              </form>

              <div className="mt-6 grow sm:mt-8 lg:mt-0">
                <div className="space-y-4 rounded-lg border border-gray-100 bg-gray-50 p-6 dark:border-gray-700 dark:bg-gray-800">
                  <div className="space-y-2">
                    <h2 className="text-center text-xl font-semibold pb-3 text-customBlue ">
                      {courseName}
                    </h2>
                    <dl className="flex items-center justify-between gap-4">
                      <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
                        Course price
                      </dt>
                      <dd className="text-base font-medium text-gray-900 dark:text-white ">
                        Rs. {strikePrice}
                      </dd>
                    </dl>

                    <dl className="flex items-center justify-between gap-4">
                      <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
                        Discount
                      </dt>
                      <dd className="text-base font-medium text-red-500">
                        Rs.{" "}
                        {discount !== 0
                          ? Math.ceil((Number(strikePrice) * discount) / 100)
                          : Number(strikePrice) - Number(currentPrice)}
                      </dd>
                    </dl>
                    <dl className="flex items-center justify-between gap-4">
                      <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
                        Current Price
                      </dt>
                      <dd className="text-base font-medium text-green-500">
                        Rs.{" "}
                        {discount == 0
                          ? Number(currentPrice)
                          : Number(strikePrice) -
                            (Number(strikePrice) * discount) / 100}
                      </dd>
                    </dl>

                    <dl className="flex items-center justify-between gap-4">
                      <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
                        Internet Handling Fee
                      </dt>
                      <dd className="text-base font-medium text-gray-900 dark:text-white">
                        Rs. 299
                      </dd>
                    </dl>
                  </div>

                  <dl className="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 dark:border-gray-700">
                    <dt className="text-base font-bold text-gray-900 dark:text-white">
                      Total
                    </dt>
                    <dd className="text-base font-bold text-gray-900 dark:text-white">
                      Rs.{" "}
                      {discount == 0
                        ? Number(currentPrice) + 299
                        : Number(strikePrice) -
                          (Number(strikePrice) * discount) / 100 +
                          299}
                    </dd>
                  </dl>

                  <button
                    onClick={() =>
                      handleCreateOrder(
                        discount == 0
                          ? Number(currentPrice) + 299
                          : Number(strikePrice) -
                              (Number(strikePrice) * discount) / 100 +
                              299
                      )
                    }
                    disabled={displayRazorpay}
                    className="bg-customBlue text-center py-[10px] w-full text-xl font-semibold text-white rounded flex items-center justify-center gap-4 disabled:bg-customRed"
                  >
                    {!displayRazorpay ? "Pay" : <Loader />}
                  </button>
                </div>

                <div className="mt-6 flex items-center justify-center gap-8">
                  <img
                    className="h-8 w-auto dark:hidden"
                    src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/paypal.svg"
                    alt=""
                  />
                  <img
                    className="hidden h-8 w-auto dark:flex"
                    src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/paypal-dark.svg"
                    alt=""
                  />
                  <img
                    className="h-8 w-auto dark:hidden"
                    src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/visa.svg"
                    alt=""
                  />
                  <img
                    className="hidden h-8 w-auto dark:flex"
                    src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/visa-dark.svg"
                    alt=""
                  />
                  <img
                    className="h-8 w-auto dark:hidden"
                    src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/mastercard.svg"
                    alt=""
                  />
                  <img
                    className="hidden h-8 w-auto dark:flex"
                    src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/brand-logos/mastercard-dark.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <p className="mt-6 text-center text-gray-500 dark:text-gray-400 sm:mt-8 lg:text-left">
              Payment processed by{" "}
              <a
                href="/razorpay.com"
                title=""
                className="font-medium text-primary-700 underline hover:no-underline dark:text-primary-500"
              >
                Razorpay
              </a>{" "}
              for{" "}
              <a
                href="https://globalipacademy.in"
                title=""
                className="font-medium text-primary-700 underline hover:no-underline dark:text-primary-500"
              >
                Global IP Academy
              </a>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default memo(CoursePayment);
