"use client";
import React, { useCallback, useContext, useEffect, useState } from "react";
// import { createOrder, paymentSuccess } from "@/services/payment";
// import { userData } from "@/services/user";
import CoursePayment from "./CoursePayment";
// import { CourseContext } from "@/Context";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { createOrder, paymentSuccess } from "../../../services/payment";
import { userData } from "../../../services/user/";
import { getTokenData } from "../../../utils";
import { GetCourseById } from "../../../services/course";
import ComponentLoading from "../../common/ComponentLoading";
import { checkCouponStatusService } from "../../../services/coupon";
import { preRegisterCouponService } from "../../../services/coupon/registerCouponCode";
import Loader from "../../common/Loader";

interface PageProps {
  // define props here
}

const Cart: React.FC<PageProps> = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [discount, setDiscount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [displayRazorpay, setDisplayRazorpay] = useState<boolean>(false);
  const [orderDetails, setOrderDetails] = useState<{
    orderId: string | null;
    currency: string | null;
    amount: number | null;
    razorpay_key: string | null;
  }>({
    orderId: null,
    currency: null,
    amount: null,
    razorpay_key: null,
  });
  const [userDetails, setUserDetails] = useState<{
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phone: string | null;
  }>({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  const [courseDetail, setCourseDetail] = useState<{
    courseName: string;
    currentPrice: number | 0;
    strikePrice: number | 0;
  }>({
    courseName: "",
    currentPrice: 0,
    strikePrice: 0,
  });

  //Get User Id
  let token: any = getTokenData();
  let userId: any;
  if (token && token?._id) {
    userId = token?._id;
  }

  useEffect(() => {
    setIsLoading(true);
    fetchUserData();
  }, []);

  //FETCH USER DATA
  const fetchUserData = async () => {
    // FETCh COURSE DETAILS
    const { data }: any = await GetCourseById(params?.courseId);
    console.log("fetch", data);
    setCourseDetail({
      ...data,
    });

    const response = await userData(userId);
    if (response?.status) {
      setUserDetails({ ...response?.data?.data });
    }
    setIsLoading(false);
  };

  console.log("setcourse Details", courseDetail);
  //PAYMENT SUCCESS HANDLER
  const paymentSuccessHandler = async (response: any) => {
    toast.success("Payment Successfully!");
    const res = await paymentSuccess(
      {
        ...response,
        courseName: courseDetail?.courseName,
        amount: Number(courseDetail?.currentPrice) + 299,
      },
      userId
    );
  };

  //PAYMENT FAILURE HANDLER
  const paymentFailureHandler = (response: any) => {
    toast.error("SOme error occured in server! Try after some time!");
  };

  // IMPLEMENT RAZORPAY
  const handleCreateOrder = useCallback(async (amount: number) => {
    try {
      setDisplayRazorpay(true);
      const { data } = await createOrder(amount, userId);

      if (data && data.order_id) {
        await openRazorpay(data);
      }
    } catch (error) {
      console.error("Error creating order:", error);
    }
  }, []);

  const loadRazorpay = (src: string) => {
    try {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
          resolve(true);
        };
        script.onerror = () => {
          resolve(false);
        };
        document.body.appendChild(script);
      });
    } catch (error) {
      console.log("error at final", error);
    } finally {
      setDisplayRazorpay(false);
    }
  };

  const openRazorpay = async (data: any) => {
    try {
      const res = await loadRazorpay(
        "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      const options = {
        key: data?.razorpay_key, // Use your Razorpay Key ID
        amount: data?.amount,
        currency: data?.currency,
        name: "Global IP Academy",
        description: courseDetail?.courseName,
        order_id: data?.orderId,
        handler: function (response: any) {
          // This handler is invoked when the payment is completed successfully
          if (response.razorpay_payment_id) {
            paymentSuccessHandler(response);
          } else {
            paymentFailureHandler(response);
          }
        },
        prefill: {
          name: userDetails?.firstName + " " + userDetails?.lastName,
          email: userDetails?.email,
          contact: userDetails?.phone,
        },
        theme: {
          color: "#0055A1",
        },
      };
      const paymentObject = new (window as any).Razorpay(options);
      await paymentObject.open();
    } catch (error) {
      console.log("error at load time", error);
    }
  };

  //CouponCode Handler
  const couponCodeHandler = useCallback(async (e: any, couponCode: string,courseName:string) => {
    // console.log("couponCode", couponCode.slice(0, 3));
    e.preventDefault();

    switch (couponCode.slice(0, 3)) {
      case "PRE":
        const response1 = await preRegisterCouponService(
          couponCode,
          courseName
        );
        if (response1.statusCode == 200) {
          console.log("response", response1.data);
          toast.success(response1.message);
          setDiscount(response1?.data?.discount);
        } else {
          toast.error(response1?.response?.data?.message?.message);
        }
        break;
      default:
        const response = await checkCouponStatusService(
          couponCode,
          courseName
        );
        if (response.statusCode == 200) {
          console.log("response", response.data);
          toast.success(response.message);
          setDiscount(response?.data?.discount);
        } else {
          toast.error(response?.response?.data?.message?.message);
        }
    }
  }, []);

  if (isLoading) {
    return <ComponentLoading />;
  }

  return (
    <>
      {/* <ComponentLoading /> */}
      <CoursePayment
        displayRazorpay={displayRazorpay}
        handleCreateOrder={handleCreateOrder}
        userDetails={userDetails}
        strikePrice={courseDetail?.strikePrice}
        currentPrice={courseDetail?.currentPrice}
        courseName={courseDetail?.courseName}
        discount={discount}
        couponCodeHandler={couponCodeHandler}
      />
    </>
  );
};

export default Cart;
