import toast from "react-hot-toast";
import { signInUser } from "../../../services/auth/signIn";
import { useState } from "react";
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../common/Loader";

const SignIn = () => {
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState<boolean>(true);
  const [isLoading,setIsLoading]=useState(false)
  const [value, setValue] = useState<{
    email: string;
    password: string;
  }>({
    email: "",
    password: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true)
    try {
      const response = await signInUser(value);
      if (response.statusCode == 200) {
        toast.success(response?.message);
        localStorage.setItem(
          "auth_token",
          JSON.stringify({
            ...response?.data?.auth_token,
            ...response?.data?.data,
          })
        );
        navigate("/dashboard");
      } else {
        toast.error("Email or Password not matched");
      }
    } catch (error) {
      throw new Error("erron on login!");
    }finally{
      setIsLoading(false)
    }
  };
  return (
    <div className="w-full h-full flex flex-col justify-center  sm:mx-auto bg-customBlue shadow-2xl shadow-customRed px-28">
      <div className="flex flex-col gap-2">
        <h2 className="font-bold text-[24px] text-start text-white">Welcome</h2>
        <h4 className=" text-[16px] text-white">
          Please enter your credentials to sign in!
        </h4>
      </div>
      <div className="w-full text-white ">
        <form
          className="flex flex-col py-4 "
          onSubmit={submitHandler}
          method="POST"
        >
          <label htmlFor="" className="text-white font-[600] text-[15px]">
            Email
          </label>
          <input
            type="email"
            name="email"
            required
            id="email"
            value={value.email}
            onChange={handleChange}
            className="border-[1px]  my-2 px-3 py-3 text-gray-800 font-semibold rounded-lg outline-[#3B82F6] mb-3"
            placeholder="Email address"
          />
          {/* {errors.email && touched.email && (
            // <span className="text-red-500 px-6">{errors.email}</span>
          )} */}
          <label htmlFor="" className="text-white font-[600] text-[15px] ">
            Password{" "}
          </label>

          <div className="  relative">
            <input
              type={passwordVisible ? "password" : "text"}
              name="password"
              required
              id="password"
              value={value.password}
              onChange={handleChange}
              className=" border-[1px]  w-full  px-3 py-3 text-gray-800 font-semibold  outline-[#3B82F6]  my-2 rounded-lg "
              placeholder="Password"
            />
            <span
              className="px-1 text-gray-600 absolute inset-y-0 right-2 pl-2 top-5 cursor-pointer "
              onClick={() => setPasswordVisible(!passwordVisible)}
            >
              {passwordVisible ? <IoEye size={23} /> : <IoEyeOff size={23} />}
            </span>
          </div>
          {/* {errors.password && touched.password && (
            // <span className="text-red-500 px-6">{errors.password}</span>
          )} */}
          <div className="flex items-center  flex-row-reverse text-white mt-3">
            <Link
              to={"/reset-password"}
              className="cursor-pointer px-2 text-[14px] hover:underline"
            >
              Forgot Password?
            </Link>
          </div>
          <button
            type="submit"
            className="px-20 py-2 bg-customRed text-[18px]  rounded text-white mt-5 w-full flex items-center gap-5 justify-center"
          >
            Login {isLoading &&<Loader/>}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
