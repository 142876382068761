import axiosObject from "../config";

export const checkCouponStatusService = async (
  coupon: any,
  courseName: string
) => {
  try {
    const uri: string = `coupon/status?coupon=${coupon}&courseName=${courseName}`;
    const response = await axiosObject.get(uri);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};
