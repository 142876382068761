import React, { useEffect, useRef, useState } from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import ReactPlayer from "react-player";

// Configure the PDF worker source
interface UploadFileProps {
  isClose?: (callback: any) => void;
  filePath?: string;
}

const VideoPlayer: React.FC<UploadFileProps> = ({ isClose, filePath }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);

  const closeHandle = (e: any) => {
    // if (e.target.id === "uploadBox") {
    isClose && isClose((prev: any) => ({ ...prev, VIDEO: false }));
    // }
  };
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden && videoRef.current) {
        videoRef.current.pause(); // Pause video when the tab is hidden
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  const handleContextMenu = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent the context menu from showing
  };
  return (
    <main
      id="uploadBox"
      // onClick={() =>
      //   isClose && isClose((prev: any) => ({ ...prev, VIDEO: false }))
      // }
      className="absolute top-0 flex justify-center items-center z-10 inset-0 bg-black bg-opacity-50"
    >
      <span
        onClick={closeHandle}
        className="absolute top-10 right-28 text-customRed h-8 w-8 rounded-full border-2 flex items-center justify-center cursor-pointer border-customRed text-xl"
      >
        X
      </span>
      <div className="bg-white w-[80vw]  rounded space-y-2  text-black  shadow-lg p-4">
        <video
          controls
          controlsList="nodownload"
          onContextMenu={handleContextMenu}
        >
          <source src={filePath} type="video/mp4" />
        </video>
      </div>
    </main>
  );
};

export default VideoPlayer;
