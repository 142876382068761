import axiosObject from "../config";

export const accessOfFolders = async (userId:string,payload: any) => {
  try {
    const uri: string = `course/create-access-of-course/${userId}`;
    const response = await axiosObject.patch(uri, payload);

    if (response.status === 201) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};
