import FolderAccess from "../../../../components/models/FolderAccess";
import Toogle from "../../../../components/common/Toogle";
import React, { memo, useState } from "react";

interface IndexProps {
  users: any;
  changeStatusOfDiscount: any;
}

const Table: React.FC<IndexProps> = ({ users, changeStatusOfDiscount }) => {
  const [isFolderAccessOpen, setIsFolderAccessOpen] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState("");

  const accessHandler = (userId: string) => {
    setSelectedUserId(userId);
    setIsFolderAccessOpen(true);
  };

  return (
    <main>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <div className="flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
       
          <label htmlFor="table-search" className="sr-only">
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 rtl:inset-r-0 rtl:right-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              id="table-search"
              className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search for items"
            />
          </div>
        </div>
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Student name
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                Phone
              </th>
              <th scope="col" className="px-6 py-3">
                Price
              </th>

              <th scope="col" className="px-6 py-3">
                Folder Access
              </th>

              <th scope="col" className="px-6 py-3">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {users?.map((item: any, index: number) => (
              <tr
                key={index}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {item?.firstName + "" + item?.lastName}
                </th>
                <td className="px-6 py-4">{item?.email}</td>
                <td className="px-6 py-4">{item?.phone}</td>
                <td className="px-6 py-4">Rs.{item?.totalAmount}</td>
                {/* <td className="px-6 py-4">
                  <Toogle />
                </td> */}
                <td
                  className="px-6 py-4 text-customBlue cursor-pointer underline"
                  onClick={() => accessHandler(item?._id)}
                >
                  Folder Access
                </td>
                <td className="px-6 py-4">
                  <Toogle
                    isActive={item?.status}
                    id={item?._id}
                    changeStatusOfDiscount={changeStatusOfDiscount}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {isFolderAccessOpen && (
        <FolderAccess userId={selectedUserId} isClose={setIsFolderAccessOpen} />
      )}
    </main>
  );
};

export default memo(Table);
