import axiosObject from "../config";

export const UpdateStatusService = async (
  couponId: string,
  status: boolean
) => {
  try {
    const uri: string = `coupon/update-status/${couponId}?status=${status}`;
    const response = await axiosObject.patch(uri);

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};
